import React from 'react'
import Layout from '../../components/Layout'
import H1 from '../../components/H1'
import { Link } from 'gatsby'
import { Accordion, Toggle } from '../../components/Accordion'

function expandAndScrollToItem(clickEvent, labelToExpand) {
  clickEvent.preventDefault()
  const targetToExpand = document.querySelector(`#${labelToExpand}:not(:checked)`)
  if (targetToExpand) targetToExpand.click() 
  setTimeout(() => {
      const el = document.querySelector(`#${labelToExpand}header`)
      el.scrollIntoView({behavior: "smooth"})
    },
    500
  )
}

export default function Resources() {
  return (
    <Layout>
      <H1 title="Resources" />
      <Accordion>
        <Toggle label="Forms">
          <ul>
            <li>
              <a
                href="/forms/ALAIR Presentation Proposal Form 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Presentation Proposal Form 2024-Fillable
              </a>
            </li>

            <li>
              <a
                href="/forms/ALAIR Sam Lowther Travel Grant 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Sam Lowther Travel Grant 2024-Fillable
              </a>
            </li>

            <li>
              <a
                href="/forms/ALAIR Sutton Leadership Award 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Sutton Leadership Award 2024-Fillable
              </a>
            </li>

            <li>
              <a
                href="/forms/ALAIR Vendor Sponsorship Form 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Vendor Sponsorship Form 2024-Fillable
              </a>
            </li>

            <li>
              <a
                href="/forms/ALAIR Conference Registration Form 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Conference Registration Form 2024-Fillable
              </a>
            </li>

            <li>
              <a
                href="/forms/ALAIR Officer Nomination Form 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Officer Nomination Form 2024-Fillable
              </a>
            </li>

            <li>
              <a
                href="/forms/ALAIR Membership Registration Form 2024-Fillable.pdf"
                target="_blank"
              >
                ALAIR Membership Registration Form 2024-Fillable
              </a>
            </li>

            <li>
              <a href="/forms/ALAIR_W9.pdf" target="_blank">
                ALAIR W-9
              </a>
            </li>
          </ul>
          <blockquote>
            ⓘ{' '}
            <a href="https://www.ct.gov/deep/cwp/view.asp?A=2690&Q=322444">
              Tips for Viewing and Using Fillable PDFs
            </a>
          </blockquote>
        </Toggle>
        <Toggle label="Awards">
          <ul>
            <li>
              <a href="/resources/suttonawards/">
                Joseph T. Sutton Leadership Award Recipients
              </a>
            </li>
          </ul>
        </Toggle>
        <Toggle label="ALAIR Conference">
          <ul>
            <li>
              <a href="/otherdocs/ALAIR-Conference-Locations.pdf" target="_blank">
                ALAIR Conference Locations
              </a>
            </li>
          </ul>
          <Toggle as="h3" label="ALAIR Conference 2024">
              <ul>
                <li>
                  <a
                    href="/forms/2024_ALAIR_Conference_Program.pdf"
                    target="_blank"
                    >
                    2024 ALAIR Conference Program
                  </a>
                </li>
                
              </ul>
            </Toggle>
          <Toggle as="h3" label="ALAIR Conference 2023">
              <ul>
                <li>
                  <a
                    href="/forms/2023_ALAIR_Conference_Program.pdf"
                    target="_blank"
                    >
                    2023 ALAIR Conference Program
                  </a>
                </li>
                <li>
                  <a
                    href="/resources/policies/minutes"
                    target="_self"
                    >
                    Annual Business Meeting Minutes
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={(event) => expandAndScrollToItem(
                      event,
                      'TreasurersReports'
                    )}
                  >
                    Annual Business Meeting Treasurer's Report
                  </a>
                </li>
              </ul>
            </Toggle>
          <Accordion>
          <Toggle as="h3" label="ALAIR Conference 2022">
              <ul>
                <li>
                  <a
                    href="/forms/2022_ALAIR_Conference_Program.pdf"
                    target="_blank"
                    >
                    2022 ALAIR Conference Program
                  </a>
                </li>
                <li>
                  <a
                    href="/resources/policies/minutes"
                    target="_self"
                    >
                    Annual Business Meeting Minutes
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    onClick={(event) => expandAndScrollToItem(
                      event,
                      'TreasurersReports'
                    )}
                  >
                    Annual Business Meeting Treasurer’s Report
                  </a>
                </li>
              </ul>
              <h4 id="conference2022presentations">
                Conference 2022 Presentations
              </h4>
              <ul>
                <li>
                  <a
                    href="/forms/AssessingStudentSatisfactionofAcademicAdvisingUnit-BroylesTeam.pdf"
                    target="_blank"
                    >
                    Assessing Student Satisfaction of Academic Advising Unit - Broyles Team
                  </a>
                </li>
                <li>
                  <a
                    href="/forms/CollegeSelectivityChoiceofMajorandPost-CollegeEarnings-IrynaMuse.pptx"
                    target="_blank"
                    >
                    College Selectivity, Choice of Major, and Post-College Earnings - Iryna Muse
                  </a>
                </li>
                <li>
                  <a
                    href="/forms/EffectiveDataVisualizationsforAll-Emily Rothenbacher.pptx"
                    target="_blank"
                    >
                    Effective Data Visualizations for All - Emily Rothenbacher
                  </a>
                </li>
                <li>
                  <a
                    href="/forms/SACSCOCReaffirmationProcess-RoseMaryWatkins.pptx"
                    target="_blank"
                    >
                    SACSCOC Reaffirmation Process - RoseMary Watkins
                  </a>
                </li>
                <li>
                  <a
                    href="/forms/UndergraduateAdultLearners-NaimaWells.pdf"
                    target="_blank"
                    >
                    Undergraduate Adult Learners - Naima Wells
                  </a>
                </li>
                <li>
                  <a
                    href="/forms/CurrentandPendingActivitiesatACHE-JimHood.pptx"
                    target="_blank"
                    >
                    Current and Pending Activities of ACHE - Jim Hood
                  </a>
                </li>
                <li>
                  <a
                    href="/forms/ManagingUp-HowtoWorkwithYourBoss'sWorkstyle-GinnyCockerill.pdf"
                    target="_blank"
                    >
                    Managing Up - How to Work with Your Boss's Workstyle - Ginny Cockerill
                  </a>
                </li>
              </ul>
            </Toggle>
          <Toggle as="h3" label="ALAIR No Conference 2021">
              <ul>
                <li>
                  <a
                    href="/minutes/2021Jul29-ALAIR_AnnualBusMTG.pdf"
                    target="_blank"
                    >
                    2021 Annual Business Meeting Minutes
                  </a>
                </li>
                <li>
                  <a
                    href="/treasurersreports/TreasurerRpt2021.pdf"
                    target="_blank"
                    >
                    2021 Annual Business Meeting Treasurer’s Report 
                  </a>
                </li>
              </ul>
            </Toggle>
            <Toggle as="h3" label="ALAIR Conference 2020">
              <ul>
                <li>
                  <a
                    href="/forms/2020_ALAIR_Conference_Program.pdf"
                    target="_blank"
                    >
                    ALAIR Conference 2020 Program
                  </a>
                </li>
                
                <li>
                  <a href="/minutes/2020Mar13-ALAIR_AnnualBusMtg.pdf"
                  target="_blank"
                  >
                    2020 Conference Minutes
                  </a>
                </li>

                <li>
                  <Link to="/conference/2020-alair-conf-images/">
                    2020 Conference Photos
                  </Link>
                </li>
                <li>
                  <a
                    href="/treasurersreports/TreasurerRpt2020.pdf"
                    download
                  >
                    2020 Conference Treasurer’s Report
                  </a>
                </li>
              </ul>
              <h4 id="conference2020presentations">
                Conference 2020 Presentations
              </h4>

              <ul>
                <li>
                  <a
                    href="/forms/KEYNOTE-StudentAchievement-Nuria Cuervas.pdf"
                    download
                  >
                    KEYNOTE - Student Achievement - Nuria Cuervas
                  </a>
                </li>
              
                <li>
                  <a
                    href="/forms/Getting Things Done-Andrew Davis.pdf"
                    download
                  >
                    Getting Things Done - Andrew Davis
                  </a>
                </li>

                <li>
                  <a
                    href="/forms/Increasing Retention of Remedial Students-Tamara Jones and Jonathan Broyles.pdf"
                    download
                  >
                    Increasing Retention of Remedial Students - Tamara Jones and Jonathan Broyles
                  </a>
                </li>

                <li>
                  <a
                    href="/forms/PowerBI-Michelle Tin.pdf"
                    download
                  >
                    PowerBI - Michelle Tin
                  </a>
                </li>
                
                <li>
                  <a
                    href="/forms/SQL Joins-Anran Xing and Michelle Tin.pdf"
                    download
                  >
                    SQL Joins - Anran Xing and Michelle Tin
                  </a>
                </li>
                
                <li>
                  <a
                    href="/forms/The First Year-Lisa Kimble.pdf"
                    download
                  >
                    The First Year - Lisa Kimble
                  </a>
                </li>
                
              </ul>
            </Toggle>
            <Toggle as="h3" label="ALAIR Conference 2019">
              <ul>
                <li>
                  <a href="/forms/2019_ALAIR_Conference_Program.pdf" target="_blank">
                    ALAIR Conference 2019 Program
                  </a>
                </li>
                <li>
                  <a href="/minutes/2019March8-ALAIR_BusMtg.pdf" target="_blank">
                    2019 Conference Minutes
                  </a>
                </li>

                <li>
                  <Link to="/conference/2019-alair-conf-images/">
                    2019 Conference Photos
                  </Link>
                </li>
                <li>
                  <a href="/treasurersreports/TreasurerRpt2019.pdf" download>
                    2019 Conference Treasurer’s Report
                  </a>
                </li>
              </ul>
              <h4 id="conference2019presentations">
                Conference 2019 Presentations
              </h4>

              <ul>
                <li>
                  <a
                    href="/forms/YBailey - Alabama Enhanced Accountability - ALAIR - March 2019.pdf"
                    download
                  >
                    Enhancing Accountability in Alabama’s Public Colleges and
                    Universities – Yardley S. Bailey,, Ph.D., University of
                    Alabama System
                  </a>
                </li>

                <li>
                  <a
                    href="/forms/acker-alair-conf-2019.zip"
                    download
                  >
                    TAG! You’re it! Collegiate Car Tag Purchases in Alabama –
                    Jon Acker, Ph.D., The University of Alabama
                  </a>
                </li>

                <li>
                  <a
                    href="/forms/Excel Macros and Carnegie Classification – Jacob Pleitz, Ph.D., The University of Alabama.zip"
                    download
                  >
                    Excel Macros and Carnegie Classification – Jacob Pleitz,
                    Ph.D., The University of Alabama
                  </a>
                </li>

                <li>
                  <a
                    href="/forms/IAIRIEPresentation.pdf"
                    download
                  >
                    IA+IR=IE: Assessing the IR Office for Greater Effectiveness
                    – Ginny Cockerill, The University of Alabama in Huntsville
                  </a>
                </li>
              </ul>
            </Toggle>
            <Toggle as="h3" label="ALAIR Conference 2018">
              <ul>
                <li>
                  <a href="/2018_ALAIR_Conference_Program.pdf">
                    2018 Conference Program
                  </a>
                </li>
                <li>
                  <a href="/minutes/2018Apr13-ALAIR_BusMtg.pdf">
                    2018 Conference Minutes
                  </a>
                </li>
                <li>
                  <Link to="/conference/2018-alair-conf-images/">
                    Conference 2018 Photos
                  </Link>
                </li>

                <li>
                  <a href="/treasurersreports/TreasurerRpt2018.pdf">
                    2018 Conference Treasurer’s Report
                  </a>
                </li>
              </ul>
              <h4 id="conference2018presentations">
                Conference 2018 Presentations
              </h4>

              <ul>
                <li>
                  <a
                    href="/forms/2018-Keynote-Purcell-Guiding-Organizational-Improvement.pptx"
                    target="_blank"
                  >
                    Guiding Organizational Improvements - Jim Purcell, Ed.D.,
                    Alabama Commission on Higher Education
                  </a>
                </li>

                <li>
                  <a
                    href="/forms/2018-Acker-Know-Your-Source.pdf"
                    target="_blank"
                  >
                    Know Your Source: ACHE Student Source Survey - Jon Acker,
                    Ph.D., The University of Alabama
                  </a>
                </li>

                <li>
                  <a
                    href="/forms/2018-Bailey-Performance-Based-Funding.pdf"
                    target="_blank"
                  >
                    Performance‐Based Funding - Yardley S. Bailey, Ph.D., The
                    University of Alabama System
                  </a>
                </li>

                <li>
                  <a href="/forms/jpleitz_alair.zip" download>
                    Creating A Visually Pleasing Dashboard In Excel Using
                    Enrollment Data - Jacob Pleitz, Ph.D., The University of
                    Alabama
                  </a>
                </li>
              </ul>
            </Toggle>
            <Toggle as="h3" label="ALAIR Conference 2017">
              <ul>
                <li>
                  <a href="/2017_ALAIR_Conference_Program.pdf">
                    2017 Conference Program
                  </a>
                </li>

                <li>
                  <a href="/conference/2017-alair-conf-images">
                    2017 Conference Pictures
                  </a>
                </li>

                <li>
                  <a href="/minutes/2017Mar10-ALAIR_BusMtg.pdf">
                    2017 Conference Minutes
                  </a>
                </li>

                <li>
                  <a href="/treasurersreports/TreasurerRpt2017.pdf">
                    2017 Conference Treasurer’s Report
                  </a>
                </li>
              </ul>
            </Toggle>
          </Accordion>
        </Toggle>
        <Toggle label="Treasurer's Reports">
          <ul>
            <li>
              <a
                href="/treasurersreports/TreasurerRpt2023.pdf">
                Treasurer's Report 2023
              </a>
            </li>
            <li>
              <a
                href="/treasurersreports/TreasurerRpt2022.pdf">
                Treasurer's Report 2022
              </a>
            </li>
            <li>
              <a
                href="/treasurersreports/TreasurerRpt2021.pdf">
                Treasurer's Report 2021
              </a>
            </li>
            <li>
              <a href="/treasurersreports/TreasurerRpt2020.pdf">
                Treasurer's Report 2020
              </a>
            </li>
            <li>
              <a href="/treasurersreports/TreasurerRpt2019.pdf">
                Treasurer's Report 2019
              </a>
            </li>
            <li>
              <a href="/treasurersreports/TreasurerRpt2018.pdf">
                Treasurer's Report 2018
              </a>
            </li>

            <li>
              <a href="/treasurersreports/TreasurerRpt2017.pdf">
                Treasurer's Report 2017
              </a>
            </li>

            <li>
              <a href="/treasurersreports/TreasurerRpt2016.pdf">
                Treasurer's Report 2016
              </a>
            </li>

            <li>
              <a href="/treasurersreports/TreasurRpt2015.pdf">
                Treasurer's Report 2015
              </a>
            </li>

            <li>
              <a href="/treasurersreports/TreasurerRpt2014.pdf">
                Treasurer's Report 2014
              </a>
            </li>
          </ul>
        </Toggle>
        <Toggle label="Historical Newsletters">
        
          <ul>
            <li>
              <a href="/newsletters/2023_02_Fall-ALAIR Newsletter.pdf">
                2023 Fall ALAIR Newsletter 
              </a>
            </li>
            <li>
              <a href="/newsletters/2022_02_Fall-ALAIR Newsletter.pdf">
                2022 Fall ALAIR Newsletter 
              </a>
            </li>
            <li>
              <a href="/newsletters/2022_01_Spring-ALAIR Newsletter.pdf">
                2022 Spring ALAIR Newsletter
              </a>
            </li>
            <li>
              <a href="/newsletters/2021_01_Fall-ALAIR Newsletter.pdf">
                2021 Fall ALAIR Newsletter
              </a>
            </li>
            <li>
              <a href="/newsletters/2020_02-ALAIR Newsletter.pdf">
                2020 Fall ALAIR Newsletter
              </a>
            </li>
            <li>
              <a href="/newsletters/2020_01_Spring-ALAIR Newsletter.pdf">
                2020 Spring ALAIR Newsletter
              </a>
            </li>
            <li>
              <a href="/newsletters/2019_02_Fall-ALAIR_Newsletter.pdf">
                2019 Fall ALAIR Newsletter
              </a>
            </li>
            <li>
              <a href="/newsletters/2019_01_Spring-ALAIR_Newsletter.pdf">
                2019 Spring ALAIR Newsletter
              </a>
            </li>
            <li>
              <a href="/newsletters/2018_02_Fall_ALAIR_Newsletter.pdf">
                2018 Fall ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2018_01_Spring-ALAIR-Newsletter.pdf">
                2018 Spring ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2017_02_Fall-ALAIR-Newsletter.pdf">
                2017 Fall ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2017_01_Winter-ALAIR-Newsletter.pdf">
                2017 Winter ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2016_01_Spring-ALAIR_Newsletter.pdf">
                2016 Spring ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2015_01_Spring-ALAIR_Newsletter.pdf">
                2015 Spring ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2014_01_Spring-ALAIR_Newsletter.pdf">
                2014 Spring ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2013_02-Fall-ALAIR_Newsletter.pdf">
                2013 Fall ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2013_01_Feb-ALAIR_Newsletter.pdf">
                2013 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2012_02_Sep-ALAIR_Newsletter.pdf">
                2012 Sep ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2012_01_Feb-ALAIR_Newsletter.pdf">
                2012 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2011_02_Nov-ALAIR_Newsletter.pdf">
                2011 Nov ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2011_01_Feb-ALAIR_Newsletter.pdf">
                2011 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2010_02_Sep-ALAIR_Newsletter.pdf">
                2010 Sep ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2010_01_Feb-ALAIR_Newsletter.pdf">
                2010 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2009_02_Aug-ALAIR_Newsletter.pdf">
                2009 Aug ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2009_01_Feb-ALAIR_Newsletter.pdf">
                2009 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2008_01_Nov-ALAIR_Newsletter.pdf">
                2008 Nov ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2007_02_Aug-ALAIR_Newsletter.pdf">
                2007 Aug ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2007_01_Feb-ALAIR_Newsletter.pdf">
                2007 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2006_01_Feb-ALAIR_Newsletter.pdf">
                2006 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2005_02_Aug-ALAIR_Newsletter.pdf">
                2005 Aug ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2005_01_Jan-ALAIR_Newsletter.pdf">
                2005 Jan ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2004_02_Aug-ALAIR_Newsletter.pdf">
                2004 Aug ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2004_01_Jan-ALAIR_Newsletter.pdf">
                2004 Jan ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2003_01_Feb-ALAIR_Newsletter.pdf">
                2003 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2002_02_Sep-ALAIR_Newsletter.pdf">
                2002 Sep ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2002_01_Jan-ALAIR_Newsletter.pdf">
                2002 Jan ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2001_02_Jul-ALAIR_Newsletter.pdf">
                2001 Jul ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2001_01_Mar-ALAIR_Newsletter.pdf">
                2001 Mar ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2000_03_Dec-ALAIR_Newsletter.pdf">
                2000 Dec ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2000_02_Jul-ALAIR_Newsletter.pdf">
                2000 Jul ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/2000_01_Feb-ALAIR_Newsletter.pdf">
                2000 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1999_04_Dec-ALAIR_Newsletter.pdf">
                1999 Dec ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1999_03_Aug-ALAIR_Newsletter.pdf">
                1999 Aug ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1999_02_Mar-ALAIR_Newsletter.pdf">
                1999 Mar ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1999_01_Jan-ALAIR_Newsletter.pdf">
                1999 Jan ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1998_02_Jul-ALAIR_Newsletter.pdf">
                1998 Jul ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1998_01_Feb-ALAIR_Newsletter.pdf">
                1998 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1997_03_Dec-ALAIR_Newsletter.pdf">
                1997 Dec ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1997_02_Jul-ALAIR_Newsletter.pdf">
                1997 Jul ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1997_01_Jan-ALAIR_Newsletter.pdf">
                1997 Jan ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1996_02_Sep-ALAIR_Newsletter.pdf">
                1996 Sep ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1996_01_Mar-ALAIR_Newsletter.pdf">
                1996 Mar ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1995_03_Dec-ALAIR_Newsletter.pdf">
                1995 Dec ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1995_02_Jul-ALAIR_Newsletter.pdf">
                1995 Jul ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1995_01_Mar-ALAIR_Newsletter.pdf">
                1995 Mar ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1994_03_Dec-ALAIR_Newsletter.pdf">
                1994 Dec ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1994_02_Summer-ALAIR_Newsletter.pdf">
                1994 Summer ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1994_01_Winter-ALAIR_Newsletter.pdf">
                1994 Winter ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1993_03_Fall-ALAIR_Newsletter.pdf">
                1993 Fall ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1993_02_Summer-ALAIR_Newsletter.pdf">
                1993 Summer ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1993_01_Feb-ALAIR_Newsletter.pdf">
                1993 Feb ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1992_02_Oct-ALAIR_Newsletter.pdf">
                1992 Oct ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1992_01_Jun-ALAIR_Newsletter.pdf">
                1992 Jun ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1991_03_Dec-ALAIR_Newsletter.pdf">
                1991 Dec ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1991_02_May-ALAIR_Newsletter.pdf">
                1991 May ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1991_01_Jan-ALAIR_Newsletter.pdf">
                1991 Jan ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1990_02_Sep-ALAIR_Newsletter.pdf">
                1990 Sep ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1990_01_Mar-ALAIR_Newsletter.pdf">
                1990 Mar ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1989_01_Mar-ALAIR_Newsletter.pdf">
                1989 Mar ALAIR Newsletter
              </a>
            </li>

            <li>
              <a href="/newsletters/1988_01_Mar-ALAIR_Newsletter.pdf">
                1988 Mar ALAIR Newsletter
              </a>
            </li>
          </ul>
        </Toggle>
        <Toggle label="Policies and Procedures">
          <ul>
            <li>
              <a href="/policies/ALAIR-Revised-Constitution-Ratified-at-2019-Annual-Conference-on-March-8.pdf">
                ALAIR Constitution
              </a>
            </li>
            <li>
              <a href="/policies/bylaws.pdf">ALAIR Bylaws</a>
            </li>
            <li>
              <a href="/resources/policies/minutes">
                Annual Business Meeting Minutes
              </a>
            </li>
            <li>
              <a href="/policies/ALAIR_calendar.pdf">
                Annual Calendar &amp; Duties
              </a>
            </li>
            <li>
              <a href="/policies/Guidelines_for_Hosting_the_Annual_Conference.pdf">
                Guidelines for Hosting the Annual Conference
              </a>
            </li>
            <li>
              <a href="/policies/Guidelines_for_Selection_of_Best_Presentation.pdf">
                Guidelines for Selection of Best Presentation
              </a>
            </li>
          </ul>
        </Toggle>
      </Accordion>
    </Layout>
  )
}
